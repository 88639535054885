body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  overflow: hidden;
}
/* 
.header {
  padding: 10px;
  background-color: rgb(194, 78, 78);
  color: #fff;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 100;
} */

.header {
  /* border: solid 5px rgba(0, 0, 0, 0.6); */
  transition: border-width 0.6s linear;
  padding: 10px;
  /* background-color: rgba(0, 0, 0, 0.6); */
  background-color: #f48037;
  color: #fff;
  opacity: 90%;

  /* margin: 0 20%; */
  /* border-radius: 40px; */
}

.header:hover {
  border-width: 10px;
}

.header h1 {
  font-size: 40px;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  padding: 0;
  margin: 0;
  text-align: center;
}

.map {
  width: 100vw;
  height: 100vh;
  position: relative;
}

.location-icon {
  font-size: 2rem;
  color: rgb(194, 78, 78);
}

.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.loader img {
  width: 400px;
}

.loader h1 {
  margin-top: -100px;
}

.location-info {
  position: absolute;
  top: 80px;
  right: 50px;
  width: 300px;
  min-height: 200px;
  padding: 20px;
  /* background-color: rgba(0, 0, 0, 0.6);
   */
  background-color: #f48037;
  opacity: 80%;
  border-radius: 10px;
  font-size: 18px;
  color: #fff;
}

.location-info ul {
  list-style-type: none;
  padding: 0;
}

.location-info li {
  padding: 5px 0;
}
